import {
  Grid,
  Theme,
  makeStyles,
  Box,
  Typography,
  IconButton,
  Tooltip,
  withStyles,
  TextField
} from '@material-ui/core'
import { realApi } from 'services/api'
import { useEffect, useState } from 'react'
import { INotification } from '../types/types'
import Timeline from '@material-ui/lab/Timeline'
import MuiTimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import emptyError from 'assets/emptyError.svg'
import TimelineDot from '@material-ui/lab/TimelineDot'
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined'
import clsx from 'clsx'
import AddAlertOutlined from '@material-ui/icons/AddAlertOutlined'
import SendNotificationDialog, {
  NotificationDialogProps
} from 'components/SendNotificationDialog'
import EmptyListFeedback from 'components/EmptyListFeedback'

type NotificationListProps = {
  groupId: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '84vh',
    backgroundColor: theme.palette.grey[100],
    overflow: 'auto'
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(5),
    width: theme.spacing(5)
  },
  text: {
    width: theme.spacing(34),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  iconPrimaryBg: {
    background:
      'radial-gradient(circle, rgba(185,185,185,1) 0%, rgba(153,153,153,1) 100%)'
  }
}))

const TimelineItem = withStyles({
  missingOppositeContent: {
    '&:before': {
      display: 'none'
    }
  }
})(MuiTimelineItem)

const NotificationList = ({ groupId }: NotificationListProps) => {
  const classes = useStyles()
  const [list, setList] = useState<INotification[] | null>()
  const [
    notificationDialog,
    setNotificationDialog
  ] = useState<NotificationDialogProps | null>()
  const [dateFilter, setDateFilter] = useState('')
  const [loading, setLoading] = useState(false)

  const getNotifications = async (id: string) => {
    setLoading(true)
    const date = dateFilter ? new Date(dateFilter) : new Date()
    const firstDay = new Date(
      date.getFullYear(),
      dateFilter ? date.getMonth() + 1 : date.getMonth(),
      1
    ).toISOString()
    const lastDay = new Date(
      date.getFullYear(),
      dateFilter ? date.getMonth() + 2 : date.getMonth() + 1,
      0
    ).toISOString()
    const response: any = await realApi.getNotifications(id, firstDay, lastDay)
    if (response.ok) {
      setList(response.data.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getNotifications(groupId)
  }, [dateFilter])

  return (
    <>
      <Box p={2} className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container alignItems="center" justify="center" spacing={2}>
              <Grid item xs="auto">
                <Typography variant="h5">
                  <Box fontWeight="fontWeightBold">Notificações</Box>
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Tooltip title="Adicionar notificação">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setNotificationDialog({
                        open: true,
                        handleClose: () => setNotificationDialog(null),
                        id: Number(groupId)
                      })
                    }}
                  >
                    <AddAlertOutlined />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Grid item xs="auto">
                    <TextField
                      size="small"
                      type="month"
                      variant="outlined"
                      onChange={e => {
                        setDateFilter(e.target.value)
                      }}
                      color="primary"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!!list && !loading && (
            <Grid item xs={12}>
              <Timeline>
                {list.map((item, index) => (
                  <TimelineItem key={`Notification ${index}`}>
                    <TimelineSeparator>
                      <TimelineDot
                        className={clsx(classes.icon, classes.iconPrimaryBg)}
                      >
                        <NotificationsIcon />
                      </TimelineDot>
                      {list.length !== index + 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="subtitle2"
                        title={item.title}
                        className={classes.text}
                      >
                        <Box fontWeight="fontWeightBold" component="span">
                          {item.title}
                        </Box>
                      </Typography>
                      <Typography title={item.body} variant="subtitle2">
                        <Box
                          fontWeight="fontWeightLight"
                          className={classes.text}
                        >
                          {item.body}
                        </Box>
                      </Typography>
                      <Typography title={item.createdAt} variant="caption">
                        <Box
                          fontWeight="fontWeightLight"
                          className={classes.text}
                        >
                          {new Date(item.createdAt).toLocaleString()}
                        </Box>
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Grid>
          )}
          {!list ||
            (list?.length === 0 && (
              <EmptyListFeedback
                src={emptyError}
                title="Lista vazia"
                description="Não foram encontradas notificações"
              />
            ))}
        </Grid>
      </Box>
      {notificationDialog && (
        <SendNotificationDialog
          {...notificationDialog}
          fetch={() => getNotifications(groupId)}
        />
      )}
    </>
  )
}

export default NotificationList
