/* eslint-disable no-template-curly-in-string */
import React from 'react'
import Card from 'components/Card'
import { Grid, TextField, Button, makeStyles, Theme } from '@material-ui/core'
import { useForm, useField } from 'react-final-form-hooks'
import ImageSelector from 'components/ImageSelector'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { ValidationErrors } from 'final-form'
import { realApi } from 'services/api'
import { useSnackbar } from '@elentari/core'
import Select from './../../../components/Select'
import { endOfDay, startOfDay } from 'date-fns'

yup.setLocale({
  mixed: {
    default: 'Não é válido',
    required: 'O campo precisa estar preenchido'
  },
  string: {
    min: 'O mínimo de caracteres é ${min}',
    max: 'O máximo de caracteres é ${max}'
  }
})

const currentDay = startOfDay(new Date())

const schema = yup.object().shape({
  title: yup.string().max(100).required(),
  segment: yup.string().required(),
  type: yup.string().required(),
  finalDate: yup
    .date()
    .min(
      currentDay,
      `A data final não pode ser anterior a ${endOfDay(
        new Date()
      ).toLocaleDateString()}`
    )
    .nullable(),
  initialDate: yup
    .date()
    .min(
      currentDay,
      `A data inicial não pode ser anterior a ${currentDay.toLocaleDateString()}`
    )
    .when(
      'finalDate',
      (finalDate: Date, schema: any) =>
        finalDate &&
        schema.max(
          finalDate,
          `A data inicial não pode ser maior que a data final`
        )
    )
    .required(),
  sequence: yup.number().max(9999).min(0.01).required()
})

export type BannerFormData = {
  title: string
  segment: string
  type: string
  banner: File
  initialDate: string
  finalDate: string
  sequence: string
}

const useStyles = makeStyles((theme: Theme) => ({
  stretchItem: {
    height: '100%'
  }
}))

const BannerForm = () => {
  const classes = useStyles()
  const { push } = useHistory()
  const [, snackbarActions] = useSnackbar()

  const onSubmit = async (values: BannerFormData) => {
    const form = new FormData()
    form.append('title', values.title)
    form.append('type', values.type)
    form.append('segment', values.segment)
    form.append('banner', values.banner)
    form.append('initialDate', values.initialDate)
    form.append('finalDate', values.finalDate)
    form.append('sequence', values.sequence)
    const response: any = await realApi.createBanner(form)
    if (response.ok) {
      snackbarActions.setSnackBar({
        severity: 'success',
        message: 'Banner criado com sucesso!'
      })
      push(`/banners/${response.data.id}`)
    } else {
      snackbarActions.setSnackBar({
        severity: 'warning',
        message: response.data.errors[0].message
      })
    }
  }

  const validate = (values: BannerFormData) => {
    try {
      schema.validateSync(values, { abortEarly: false })
    } catch (errors: any) {
      const formErrors: any = {}
      errors.inner.forEach((erro: ValidationErrors) => {
        formErrors[erro?.path] = erro?.message
      })
      return formErrors
    }
  }

  const {
    form,
    handleSubmit,
    values,
    pristine,
    submitting,
    initialValues
  } = useForm({
    onSubmit,
    validate
  })

  const banner = useField('banner', form)
  const title = useField('title', form)
  const segment = useField('segment', form)
  const type = useField('type', form)
  const initialDate = useField('initialDate', form)
  const finalDate = useField('finalDate', form)
  const sequence = useField('sequence', form)

  return (
    <Card backButton={e => push('/banners')} title="Adicionar banner">
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={5}>
                <ImageSelector
                  width="100%"
                  height="208px"
                  value={banner.input.value}
                  onChange={banner.input.onChange}
                />
              </Grid>
              <Grid item xs>
                <Grid
                  container
                  alignContent="space-between"
                  className={classes.stretchItem}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Título"
                          size="small"
                          variant="outlined"
                          fullWidth
                          {...title.input}
                          error={title.meta.touched && title.meta.invalid}
                          helperText={
                            title.meta.touched &&
                            title.meta.invalid &&
                            title.meta.error
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Select
                          label="Segmento"
                          value={segment.input.value}
                          onChange={segment.input.onChange}
                          error={segment.meta.touched && segment.meta.invalid}
                          helperText={
                            segment.meta.touched &&
                            segment.meta.invalid &&
                            segment.meta.error
                          }
                          items={[
                            { label: 'Comida', value: 'FOOD' },
                            { label: 'Farmacêutico', value: 'FARMA' },
                            { label: 'Ambos', value: 'AMBOS' }
                          ]}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Select
                          label="Tipo"
                          value={type.input.value}
                          onChange={type.input.onChange}
                          error={type.meta.touched && type.meta.invalid}
                          helperText={
                            type.meta.touched &&
                            type.meta.invalid &&
                            type.meta.error
                          }
                          items={[
                            { label: 'Produtos', value: 'PRODUCTS' },
                            { label: 'Seções', value: 'SECTIONS' },
                            { label: 'Avisos', value: 'WARNING' }
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Data inicial"
                          size="small"
                          type="date"
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          {...initialDate.input}
                          error={
                            initialDate.meta.touched && initialDate.meta.invalid
                          }
                          helperText={
                            initialDate.meta.touched &&
                            initialDate.meta.invalid &&
                            initialDate.meta.error
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Data final"
                          size="small"
                          variant="outlined"
                          type="date"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          {...finalDate.input}
                          error={
                            finalDate.meta.touched && finalDate.meta.invalid
                          }
                          helperText={
                            finalDate.meta.touched &&
                            finalDate.meta.invalid &&
                            finalDate.meta.error
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Ordem"
                          type="number"
                          size="small"
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            step: 0.01
                          }}
                          {...sequence.input}
                          error={sequence.meta.touched && sequence.meta.invalid}
                          helperText={
                            sequence.meta.touched &&
                            sequence.meta.invalid &&
                            sequence.meta.error
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify="flex-end">
                      <Grid item xs="auto">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={pristine || submitting}
                        >
                          criar banner
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Card>
  )
}

export default BannerForm
