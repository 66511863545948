/* eslint-disable no-template-curly-in-string */
import {
  Modal,
  Backdrop,
  makeStyles,
  Grid,
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress,
  TextField
} from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import { useForm, useField } from 'react-final-form-hooks'
import { ValidationErrors } from 'final-form'
import * as yup from 'yup'
import { realApi } from 'services/api'
import { useSnackbar } from '@elentari/core'
import Select from './Select'
import { log } from 'console'

yup.setLocale({
  mixed: {
    default: 'Não é válido',
    required: 'O campo precisa estar preenchido'
  },
  string: {
    min: 'O mínimo de caracteres é ${min}',
    max: 'O valor máximo de caracteres é ${max}'
  }
})

const schema = yup.object().shape({
  title: yup.string().max(50).required(),
  body: yup.string().max(150).required(),
  segment: yup.string().oneOf(['FOOD', 'FARMA', 'AMBOS'])
})

export type NotificationDialogProps = {
  open: boolean
  id: number
  handleClose: () => void
  fetch?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    position: 'relative'
  },
  backdrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(66, 66, 66,0.6)'
  },
  divModal: {
    maxWidth: '350px',
    position: 'relative'
  },
  imgWrapper: {
    width: '100%',
    height: '200px',
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1) * -1
  },
  img: {
    position: 'absolute',
    top: theme.spacing(10) * -1,
    '-webkit-filter': 'drop-shadow(2px 2px 10px rgba(0,0,0,0.5))',
    filter: 'url(#drop-shadow)',
    '-ms-filter':
      "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')"
  },
  titleSize: {
    fontSize: theme.spacing(4)
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  buttonWrapper: {
    position: 'relative'
  }
}))

export type NotificationDataType = {
  title: string
  body: string
  segment: 'FOOD' | 'FARMA' | 'AMBOS'
}

const SendNotificationDialog = ({
  open,
  id,
  fetch,
  handleClose
}: NotificationDialogProps) => {
  const classes = useStyles()
  const [, snackbarActions] = useSnackbar()

  const validate = (values: NotificationDataType) => {
    try {
      schema.validateSync(values, { abortEarly: false })
    } catch (errors: any) {
      const formErrors: any = {}
      errors.inner.forEach((erro: ValidationErrors) => {
        formErrors[erro?.path] = erro?.message
      })
      return formErrors
    }
  }

  const onSubmit = async (values: NotificationDataType) => {
    const response: any = await realApi.sendNotification(id, values)
    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Notificação enviada com sucesso',
        severity: 'success'
      })
      if (fetch) {
        fetch()
      }
      handleClose()
    } else {
      snackbarActions.setSnackBar({
        message: response.data.message,
        severity: 'warning'
      })
      handleClose()
    }
  }

  const { form, handleSubmit, values, pristine, submitting } = useForm({
    onSubmit, // the function to call with your form values upon valid submit
    validate // a record-level validation function to check all form values
  })

  const title = useField('title', form)
  const body = useField('body', form)
  const segment = useField('segment', form)

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-body"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        className: classes.backdrop
      }}
      className={classes.modal}
    >
      <div className={classes.divModal}>
        <div className={classes.imgWrapper}>
          <img
            height="270px"
            src="/send-notification-illustration.png"
            alt="Enviando notificação"
            className={classes.img}
          />
        </div>
        <Paper>
          <Box p={2}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography color="textPrimary" align="center" variant="h5">
                    <Box fontWeight="fontWeightBold">Enviar notificação</Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Select
                    label="Segmento"
                    value={segment.input.value}
                    onChange={segment.input.onChange}
                    error={segment.meta.touched && segment.meta.invalid}
                    helperText={
                      segment.meta.touched &&
                      segment.meta.invalid &&
                      segment.meta.error
                    }
                    items={[
                      { label: 'Comida', value: 'FOOD' },
                      { label: 'Farmacêutico', value: 'FARMA' },
                      { label: 'Ambos', value: 'AMBOS' }
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Título da notificação"
                    color="primary"
                    variant="outlined"
                    size="small"
                    fullWidth
                    {...title.input}
                    error={title.meta.touched && title.meta.invalid}
                    helperText={
                      title.meta.touched &&
                      title.meta.invalid &&
                      title.meta.error
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...body.input}
                    label="Corpo da notificação"
                    color="primary"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={2}
                    fullWidth
                    onChange={e =>
                      e.target.value.length <= 150
                        ? body.input.onChange(e)
                        : null
                    }
                    error={body.meta.touched && body.meta.invalid}
                    helperText={
                      (body.meta.touched &&
                        body.meta.invalid &&
                        body.meta.error) || (
                        <Typography variant="caption">
                          {150 - body.input.value.length} Caracteres restantes
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        onClick={handleClose}
                        color="secondary"
                        variant="outlined"
                        fullWidth
                      >
                        Fechar
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.buttonWrapper}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          fullWidth
                          disabled={pristine || submitting}
                        >
                          Enviar
                        </Button>
                        {submitting && (
                          <CircularProgress
                            color="primary"
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </div>
    </Modal>
  )
}

export default SendNotificationDialog
