import { Item } from '../../components/AppWrap/types'
import { FiUser, FiLink2, FiUsers, FiTag } from 'react-icons/fi'
import { BiCarousel, BiSitemap } from 'react-icons/bi'

const menuItems: Item[] = [
  {
    name: 'users',
    label: 'Usuários',
    pathname: '/users',
    icon: FiUser,
    group: false
  },
  {
    name: 'users-companies',
    label: 'Vínculo CNPJ pendente',
    pathname: '/users-companies?sort%5Bstatus%5D=desc&status=PENDING',
    icon: FiLink2,
    group: false
  },
  {
    name: 'group',
    label: 'Grupos',
    pathname: '/group',
    icon: FiUsers,
    group: false
  },
  {
    name: 'promotion',
    label: 'Promoção',
    pathname: '/promotion',
    icon: FiTag,
    group: false
  },
  {
    name: 'banners',
    label: 'Banners',
    pathname: '/banners',
    icon: BiCarousel,
    group: false
  },
  {
    name: 'departments',
    label: 'Departamentos',
    pathname: '/departments',
    icon: BiSitemap,
    group: false
  }
]

export default menuItems
