/* eslint-disable prefer-const */
import {
  Box,
  Button,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Edit from '@material-ui/icons/EditOutlined'
import { ChangeEvent, useRef, useState } from 'react'
import { realApi } from 'services/api'
import { SkeletonCard } from './Skeleton'
import { IHandleUpdateValues, Props } from './types'
import Pagination from 'components/Pagination'
import EmptyListFeedback from 'components/EmptyListFeedback'
import Spacer from 'components/Spacer'
import emptyError from 'assets/emptyError.svg'

const useStyles = makeStyles(theme => ({
  imageContainer: {
    backdropFilter: 'blur(8px)',
    width: '100%',
    height: '100%',
    margin: '0 auto'
  },
  cardMedia: {
    width: '256px',
    height: '154px'
  },
  root: {
    marginTop: '8px'
  },
  cardContent: {
    paddingBottom: '16px'
  },
  buttonWrapper: {
    marginTop: '20px'
  },
  imageInput: {
    display: 'none'
  }
}))

export const ListItems = ({
  list,
  refreshInformation,
  loading,
  goFoward
}: Props) => {
  const [saving, setSaving] = useState(false)
  const classes = useStyles()
  const theme = useTheme()
  const inputRef: any = useRef(null)
  const [imagePromotionCode, setImageHover] = useState<number | null>(null)
  const [selectPromotionCode, setSelectedPromotionCode] = useState<number>()

  async function handleChangeStatus({
    promotionCode,
    file,
    status
  }: IHandleUpdateValues) {
    setSaving(true)
    const form = new FormData()
    if (file) {
      form.append('photo', file)
    }
    await realApi.updateDepartmentPhoto(promotionCode, form)
    await refreshInformation()
    setSaving(false)
  }
  function handleSelectImage(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const file = event.target.files[0]
      selectPromotionCode &&
        handleChangeStatus({ promotionCode: selectPromotionCode, file })
    }
  }

  if (loading) {
    return <SkeletonCard />
  }

  return (
    <Box marginLeft={3} pb={2}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="body1">
            <Box fontWeight="fontWeightBold">PROMOÇÕES</Box>
          </Typography>
        </Grid>
        <Grid container alignItems="stretch" spacing={3}>
          {!!list.length &&
            list.map(({ descricao, segmento, departmentId, banner }) => {
              const hovering = imagePromotionCode === departmentId
              return (
                <Grid item key={descricao} xs={6}>
                  <Card
                    style={{
                      borderRadius: theme.spacing(1),
                      backgroundColor: '#f0f0f0',
                      height: '100%'
                    }}
                    elevation={0}
                  >
                    <Grid container alignItems="stretch">
                      <Grid item xs="auto">
                        <CardMedia
                          image={banner || '/image-proportion.png'}
                          onMouseEnter={() => setImageHover(departmentId)}
                          onMouseLeave={() => setImageHover(null)}
                          className={classes.cardMedia}
                        >
                          {hovering && (
                            <Grid
                              className={classes.imageContainer}
                              container
                              justify="center"
                              alignItems="center"
                            >
                              <Button
                                startIcon={<Edit />}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setSelectedPromotionCode(departmentId)
                                  inputRef.current.click()
                                }}
                              >
                                ALTERAR
                              </Button>
                            </Grid>
                          )}
                        </CardMedia>
                      </Grid>
                      <Grid item xs>
                        <CardContent className={classes.cardContent}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h6"
                              style={{
                                maxWidth: '305px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                color: '#000'
                              }}
                            >
                              {descricao}
                            </Typography>
                          </Grid>
                          <Grid container xs={12} justify="space-between">
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  color: '#A1A1A1'
                                }}
                              >
                                {segmento}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              )
            })}
        </Grid>
        {list.length === 0 && !loading && (
          <Grid item xs={12}>
            <EmptyListFeedback
              src={emptyError}
              title="LISTA VAZIA"
              description="Ainda não temos promoções adicionadas!"
            />
            <Spacer y={4} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Pagination
            disableFoward={!goFoward}
            count={11}
            onlyArrows
            hideLimit
          />
        </Grid>
      </Grid>
      <input
        type="file"
        ref={inputRef}
        accept="image/*"
        className={classes.imageInput}
        onChange={handleSelectImage}
      />
    </Box>
  )
}
