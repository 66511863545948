import { ApiResponse, create } from 'apisauce'
import { NotificationDataType } from 'components/SendNotificationDialog'
import { BannerFormData } from 'modules/banners/form/BannerForm'
import { UsersCompanies } from 'modules/userCompanies/types/types'
import { BannerSegment } from 'types/generalTypes'
import { IStatus } from 'types/status'
import config from '../config'
import { LoginCredentials } from '../modules/login/types'
import { fixture } from './mockData'
import {
  DataResponseLogin,
  DataResponseCompanies,
  DataResponseProducts,
  DataResponseSections,
  DataResponseDepartments
} from './types'

export const apiSauceInstance = create({
  baseURL: config.baseURL
})

async function uploadFile(file: File): Promise<string | undefined> {
  const formData = new FormData()
  formData.append('file', file)

  const response = await apiSauceInstance.post<{ id?: string }>(
    'files/upload',
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  )

  if (response.data) {
    return response.data.id
  }
}

export const realApi = {
  setToken(token: string) {
    return apiSauceInstance.setHeader('Authorization', `Bearer ${token}`)
  },
  updateGroup: async (id: string, data: { name: string }) => {
    return await apiSauceInstance.patch(`groups/${id}`, {
      ...data
    })
  },
  ping: () => apiSauceInstance.get('/me'),
  sendNotification: async (groupId: number, data: NotificationDataType) => {
    return await apiSauceInstance.post('groups-notifications', {
      groupId: groupId,
      title: data.title,
      body: data.body,
      segment: data.segment,
      type: 'GENERAL'
    })
  },
  uploadFile: async (files: File[]) => Promise.all(files.map(uploadFile)),
  async linkFile(
    companyId: string,
    fileId: string
  ): Promise<ApiResponse<{ count: number }>> {
    return apiSauceInstance.post(`companies/${companyId}/files`, [
      {
        fileId
      }
    ])
  },
  deleteCompanieFromGroup: async (id: number) => {
    return await apiSauceInstance.delete(`groups-companies/${id}`)
  },
  deleteCNPJLinkFromUser: async (id: number) => {
    return await apiSauceInstance.delete(`users-companies/${id}`)
  },
  deleteBannerProductItem: async (id: number) => {
    return await apiSauceInstance.delete(`banners-products/${id}`)
  },
  deleteBannerSectionItem: async (id: number) => {
    return await apiSauceInstance.delete(`banners-sections/${id}`)
  },
  getNotifications: async (
    groupId: string,
    startDate: string,
    finalDate: string
  ) => {
    return await apiSauceInstance.get(
      `groups-notifications?groupId=${groupId}&$sort[createdAt]=-1`,
      {},
      {
        params: {
          startDate,
          finalDate,
          $limit: 999
        }
      }
    )
  },
  sendRecoverPassEmail: async (email: string) => {
    return await apiSauceInstance.post(`recover-password-requests`, { email })
  },
  updateUserStatus: async (id: number, status: string) => {
    return await apiSauceInstance.patch(`users/${id}`, { status })
  },
  recoverPass: async (password: string, token: string) => {
    return await apiSauceInstance.post(`recover-password-confirmations`, {
      password,
      token
    })
  },
  updateDepartmentPhoto: async (departmentId: number, form: FormData) =>
    apiSauceInstance.patch(`departments-photos/${departmentId}`, form),
  updateGeneralPromotions: async (promotionCode: number, form: FormData) =>
    apiSauceInstance.patch(`promotions/${promotionCode}`, form),
  createBanner: async (data: FormData) => {
    return await apiSauceInstance.post('banners', data)
  },
  editBanner: async (id: number, data: FormData | any) => {
    return await apiSauceInstance.patch(`banners/${id}`, data)
  },
  getPromotions: async (params?: any): Promise<ApiResponse<any>> =>
    await apiSauceInstance.get('general-promotions', params),
  getCompanies: async (
    search?: string,
    limit?: number
  ): Promise<ApiResponse<DataResponseCompanies[]>> =>
    await apiSauceInstance.get(
      `companies`,
      {},
      {
        params: {
          $limit: limit,
          search: search?.toUpperCase()
        }
      }
    ),
  getSections: async (
    segment: BannerSegment['segment'],
    search?: string,
    limit?: number
  ): Promise<ApiResponse<DataResponseSections[]>> =>
    await apiSauceInstance.get(
      `general-sections?segment=${segment}`,
      {},
      {
        params: {
          $limit: limit,
          search: search?.toUpperCase()
        }
      }
    ),
  addProductToBanner: async (bannerId: number, productId: number) => {
    return await apiSauceInstance.post('banners-products', {
      bannerId,
      productId
    })
  },
  addSectionToBanner: async (bannerId: number, sectionId: number) => {
    return await apiSauceInstance.post('banners-sections', {
      bannerId,
      sectionId
    })
  },
  getDepartments: async (): Promise<ApiResponse<DataResponseDepartments[]>> =>
    await apiSauceInstance.get(`departments-photos`, {}, {}),
  getProducts: async (
    segment: BannerSegment['segment'],
    search?: string,
    limit?: number
  ): Promise<ApiResponse<DataResponseProducts[]>> =>
    await apiSauceInstance.get(
      `general-products?segment=${segment}`,
      {},
      {
        params: {
          $limit: limit,
          search: search?.toUpperCase()
        }
      }
    ),
  getFile: async (id: string) => apiSauceInstance.get(`files/${id}`),
  updateStatus: async (status: IStatus['status'], id: number) => {
    return await apiSauceInstance.patch(`users-companies/${id}`, {
      status
    })
  },
  getUserCompanies: async (id: number) =>
    await apiSauceInstance.get(`users-companies?userId=${id}`),
  insertGroupCompanies: async (groupId: string, companies: string[]) =>
    apiSauceInstance.post(`groups-companies`, {
      groupId,
      companies
    }),
  linkCompanies: async (userId: number, companies: string[]) =>
    apiSauceInstance.post(`link-companies`, {
      userId,
      companies
    }),
  getUserCompaniesDetails: async (
    userCompaniesId: number
  ): Promise<ApiResponse<{ data: UsersCompanies[] }>> =>
    apiSauceInstance.get(`users-companies?id=${userCompaniesId}`),
  async login({
    username,
    password
  }: LoginCredentials): Promise<
    ApiResponse<DataResponseLogin, { code: number }>
  > {
    return apiSauceInstance.post('/authentication', {
      email: username,
      password,
      strategy: 'admin'
    })
  }
}

const api =
  process.env.NODE_ENV === 'development' && config.useFixture
    ? fixture
    : realApi

export default api
