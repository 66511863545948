import { useSnackbar } from '@elentari/core'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { realApi } from 'services/api'
import { DataResponsePromotions } from 'services/types'
import { ListItems } from './ListItems'

const Promotions = () => {
  const history = useHistory()
  const [data, setData] = useState<DataResponsePromotions[]>([])
  const [loading, setLoading] = useState(false)
  const [, snackbarActions] = useSnackbar()
  const urlParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search]
  )

  useEffect(() => {
    const page = urlParams.get('page')
    const limit = urlParams.get('size')
    const skip = page ? Number(page) * Number(limit) - Number(limit) : 0
    fetchData({ $limit: limit, $skip: skip })
  }, [urlParams])

  async function fetchData(params?: any) {
    setLoading(true)
    const response = await realApi.getPromotions(params)
    if (!response.ok) {
      snackbarActions.setSnackBar({
        message: 'Houve um erro ao carregar os dados, contate o suporte!',
        severity: 'warning'
      })
    }
    setData(response.data)
    setLoading(false)
  }

  return (
    <ListItems
      list={data}
      goFoward={data?.length === 10}
      loading={loading}
      refreshInformation={() => fetchData()}
    />
  )
}

export default Promotions
